import http from '@/units/request'
import axios from 'axios'
let cancelList = null
/** *
 * 获取单位终端设备列表
 * ***/
export const getCompanyTerminalList = (data) => {
  if (cancelList !== null && data['page.current'] === 1) {
    cancelList('取消请求') // 如果上一次的请求还在继续，则取消
  }
  return http({
    url: '/is-equipment/terminal/v2/list',
    method: 'get',
    params: data,
    cancelToken: new axios.CancelToken(function (c) {
      cancelList = c
    })
  })
}
/** *
 * 获取单位终端设备详细
 * ***/
export const getCompanyTerminalInfo = (terminalId) => {
  return http({
    url: `/is-equipment/terminal/v2/info/${terminalId}`,
    method: 'get'
  })
}
